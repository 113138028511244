.layout {
    flex-direction: column;
    display: flex;
    min-height: 100%;
}

.layout header {
    padding: 0 36px;
    min-height: 48px;
    height: fit-content !important;
    line-height: 23px !important;
    display: flex;
}

.layout .content {
    flex: 1;
    padding: 50px;
}

.layout .footer {
    background: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 0;
    margin: 0 50px;
    line-height: 28px;
    border-top: 1px solid #d9d9d9;
}

.layout .footer .links a {
    font-weight: 600;
    color: rgba(0, 21, 75, 1);
    letter-spacing: 0.8px;
}

.layout .footer a {
    margin-left: auto;
    font-size: 14px;
    color: rgba(0, 21, 75, 0.3);
}
