.selectors {
    margin-bottom: 28px;
}

.selector {
    display: flex;
    align-items: center;
}

.selector .label {
    width: 70px;
}

.selector .hubs {
    flex-grow: 1;
    margin-left: 10px;
}

.selector .projects {
    flex-grow: 1;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    width: 75%;
}

.selector .statuses {
    flex-grow: 1;
    margin-left: 10px;
}

.selector .types {
    flex-grow: 1;
    margin-left: 10px;
}

.selector .rootCauses {
    flex-grow: 1;
    margin-left: 10px;
}

.selector .assignees {
    flex-grow: 1;
    margin-left: 10px;
}

.goToAccButton {
    display: flex;
    align-items: center;
    .arrowRight {
        margin-left: 10px;
        font-size: 16px;
    }
}

.secondFilters {
    margin-top: 20px;
}
