.select-table .ant-pagination {
    display: flex;
    align-items: flex-end;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.select-table .ant-pagination .ant-pagination-total-text {
    flex: 1;
    height: auto !important;
}

.select-table .ant-pagination .ant-pagination-total-text .ant-collapse .ant-collapse-header {
    padding-bottom: 0 !important;
}

.select-table .ant-pagination .ant-pagination-total-text .ant-collapse .ant-collapse-content-box {
    margin-top: 6px !important;
    padding-bottom: 0 !important;
}

.select-table .ant-pagination:last-child .ant-pagination-total-text .ant-collapse {
    display: none !important;
}
