.selector .hubs {
    margin-left: 28px;
    min-width: 280px;
}

.selector {
    margin-bottom: 28px;
}

.pagination li:nth-child(3) {
    display: none;
}