.application {
    background: white;
    aspect-ratio: 8/11;
    width: 100%;
    display: grid;
    grid-template: 65% 35% / 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media only screen and (min-width: 550px) and (max-width: 1320px) {
        grid-template: 60% 40% / 100%;
    }

    img {
        max-width: 100%;
    }

    .title {
        font-family: 'Roboto_Bd';
        font-weight: 600;
        line-height: 133.4%;
        font-size: 1.5rem;
        margin-bottom: 0;
    }

    .description {
        font-family: 'Roboto_Rg';
        font-weight: 400;
        font-size: 1rem;
        line-height: 150%;
        margin-bottom: 0;
    }
}

.homepagelayout {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    min-height: 70vh;
}

.boxes {
    align-self: center;
    justify-content: center;
    max-width: 1340px;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    @media only screen and (max-width: 950px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 550px) {
        grid-template-columns: 1fr;
    }
}

.box {
    background: inherit;
    position: relative;
}

.textbox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 24px 16px;
}

.image {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

.application.application.disabled {
    cursor: default;
}

.application.disabled {
    opacity: 0.6;
    display: none;
}

.application .chips {
    padding: 19.25px 16px;
    align-items: center;
    display: flex;
    gap: 16px;

    @media only screen and (min-width: 1300px) {
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.application .chip {
    font-family: 'Roboto_Rg';
    padding: 6px 12px 6px 12px;
    background: #e0e0e0;
    color: #000;
    font-size: 14px;
    border-radius: 16px;
}
