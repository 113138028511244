.result {
    display: flex;
    justify-content: center;
    font-size: 16px;
    .icon {
        font-size: 20px;
        color: green;
        vertical-align: middle;
        margin-right: 10px;
    }
    a {
        color: #ff9900;
    }
}

.buttons {
    text-align: center;
    margin-top: 28px;
}
