.attribute {
    display: flex;
}

.attribute .input,
.attribute .date,
.attribute .select {
    margin-left: 14px;
}

.attribute .select {
    width: 100% !important;
}
