.label {
    &.action {
        button {
            margin-left: 10px;
            span {
                color: '#333f50';
            }
        }
    }
}

.tag {
    margin-left: 16px;
    vertical-align: middle;
}
