.modal {
    & > div:nth-child(2) {
        min-width: 0px !important;
    }
    .project {
        width: 600px;
    }

    .result {
        display: flex;
        font-size: 16px;
        .icon {
            font-size: 32px;
            color: green;
            vertical-align: middle;
            margin-right: 24px;
        }
        a {
            color: #ff9900;
        }
    }

    .task {
        margin-left: 8px;
    }
}
