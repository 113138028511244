.icon {
    vertical-align: middle;
    margin-right: 8px;

    span {
        font-size: 18px;
    }
}

.procore {
    width: 18px;
}
