.buttons {
    margin-top: 28px;
}

.modal .head {
    font-weight: bold;
}

.modal .head span {
    margin-right: 14px;
}

.modal .head span i {
    background-color: #ff9900;
}
