.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header span,
.header a {
    color: #fff;
}

.header .logobox {
    display: flex;
    gap: 0.25em;
    align-items: center;
}

.header .apps {
    min-width: 150px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.header .settings {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 42px;
    justify-content: space-between;
}

.header .settings .tasks {
    color: #fff;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.header .settings .active {
    color: #fff;
}

.header .space {
    display: flex;
    gap: 0 !important;
    align-items: center;
}

.header .spaceitem {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-family: 'Roboto_Md';
    letter-spacing: 0.15008px;
    line-height: 1.5;
    margin-left: 18px;
}

.header .settings .user {
    font-size: 14px;
    line-height: 1.5em;
    vertical-align: sub;
    font-family: 'Roboto_Bd';
    align-self: center;
}

.header .settings .active .user {
    color: #ff9900;
}

.header .settings .signout {
    cursor: pointer;
    display: flex;
    align-items: center;
    .logo {
        width: 24px;
        margin-right: 8px;
    }
}

.header .settings .organizations {
    margin-right: 8px;
    font-family: 'Roboto_Md';
    letter-spacing: 0.15008px;
    line-height: 1.5;
}

.header .settings .verticalDivider {
    margin: 0 0.5rem;
    height: 25px;
    border-left: 1px solid #333;
}

.appMenuLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    flex-shrink: 0;

    .title {
        margin-left: 7px;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
    }
}
